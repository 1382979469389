<template>
  <div class="job-lists-wrapper">
    <div class="banner">
      <img src="@/assets/image/banner1.png" />
    </div>
    <job-list-template ref="jobList" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import JobListTemplate from '@/components/jobListTemplate'
export default {
  components: {
    JobListTemplate
  },
  data() {
    return {
      popUp: false //控制退出应用的组件显示
    }
  },
  computed: {
    ...mapState(['channel', 'token'])
  },
  created() {
    if (this.token) {
      this.$refs.jobList.getInterviewTrainingCategoryFn()
    } else {
      const channel = localStorage.getItem('channel')
      if (channel) {
        this.$router.push(`/?channel=${channel}`)
      } else {
        this.$router.push(`/`)
      }
    }
  },
  methods: {
    // 跳转页面
    onSkipPageFn(url, item) {
      this.$router.push({
        query: {
          id: item.id
        },
        path: url
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.job-lists-wrapper
  width 100%
  height auto
</style>

<template>
  <div class="job-lists-wrapper">
    <div class="nav-box">
      <ul v-if="obj.navLists.length">
        <li
          v-for="(item, index) in obj.navLists"
          @click="onNavFn(item, index)"
          :class="{ active: currentIndex === index }"
          :key="index"
        >
          {{ item.name }}
          <div class="line"></div>
        </li>
      </ul>
    </div>
    <div class="center-box">
      <div class="content-wrapper">
        <ul v-if="obj.jobLists.length">
          <li v-for="(item, index) in obj.jobLists" @click="getInterviewTrainingSpecialFn(item, index)" :key="index">
            <div class="img">
              <img :src="item.cover" />
              <div class="img-text" :class="{ 'img-text-color': item.type === 1 }">
                <div class="name">{{ item.title }}</div>
                <div class="text">共{{ item.special_num }}个专题</div>
              </div>
            </div>
            <div class="title">{{ item.intro }}</div>
            <div v-if="selectObj && item.popUpsState" class="mask-fixed">
              <el-button
                v-for="(obj, i) in selectObj.list"
                :type="obj.level === 1 ? 'success' : 'warning'"
                :key="i"
                size="mini"
                @click.stop="onSelectTopicFn(obj, index)"
              >
                {{ obj.level === 1 ? '基础面试' : '进阶面试' }}
              </el-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--选择基础/进阶弹窗-->
    <!--<el-dialog
      v-if="selectObj"
      :visible.sync="popUpsState"
      :show-close="false"
      :center="true"
      :modal="false"
      :title="selectObj.training.title"
      append-to-body
      width="300px"
      top="30vh"
    >
      <div class="pop-up-box">
        <el-button
          v-for="(item, index) in selectObj.list"
          :type="item.level === 1 ? 'success' : 'warning'"
          :key="index"
          @click="onSelectTopicFn(item)"
        >{{ item.level === 1 ? '基础面试' : '进阶面试' }}</el-button>
      </div>
    </el-dialog>-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      currentIndex: 0,
      popUpsState: false,
      selectObj: null // 选中岗位信息
    }
  },
  computed: {
    ...mapState(['channel'])
  },
  methods: {
    ...mapActions(['getInterviewTrainingSpecial']),
    // 菜单栏切换
    onNavFn(item, index) {
      this.currentIndex = index
      this.$emit('getListByCate', item.id)
    },
    // 获取面试岗位详情
    async getInterviewTrainingSpecialFn(item, index) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.selectObj = await this.getInterviewTrainingSpecial({ id: item.id, channel: this.channel })
      this.obj.jobLists.forEach(obj => {
        obj.popUpsState = false
      })
      this.obj.jobLists[index].popUpsState = true
      loading.close()
    },
    // 选择题目
    onSelectTopicFn(item, index) {
      this.obj.jobLists[index].popUpsState = false
      this.$emit('selectTopic', item.id)
    }
  },
  props: {
    obj: Object
  }
}
</script>

<style lang="stylus" scoped>
.job-lists-wrapper
  width: 100%
  height: auto
  .nav-box
    width: 100%
    height: auto
    padding-top: 20px
    z-index: 999
    ul
      width: 100%
      height: auto
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      li
        position: relative
        padding: 0 10px;
        margin-bottom: 10px
        font-size: 12px
        line-height: 22px
        cursor: pointer
        border: 1px solid transparent
        color: #fff
        transition: all .2s
        border-radius: 4px
        box-sizing: border-box
      .active
        border-color: #6ad06f
        color: #6ad06f
  .center-box
    width: 100%
    height: auto
    margin: auto
    .content-wrapper
      width: 100%
      height: auto
      ul
        width: 100%
        height: auto
        display: flex
        flex-wrap: wrap
        li
          position: relative
          width: 30%
          height: auto
          padding-bottom: 5px
          margin: 0 24px 24px 0
          border-radius: 8px
          transition: all .2s
          cursor: pointer
          box-shadow: 0 6px 10px 0 rgba(95, 101, 105, 0.15)
          background: #fff
          .img
            position: relative
            width: 100%
            height: auto
            border-radius: 8px 8px 0 0
            overflow: hidden
            img
              width: 100%
              height: 100%
              object-fit: cover
            .img-text
              position: absolute
              left: 0
              top: 0
              width: 100%
              height: 100%
              padding: 40px 20px
              z-index: 10
              color: #333
              box-sizing: border-box
              &.img-text-color
                color: #fff
              .name
                font-size: 18px
                font-weight: bold
              .text
                font-size: 14px
                padding-top: 10px
          .title
            line-height: 26px
            height: 26px
            padding: 0 8px
            overflow: hidden
            font-size: 2px
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
            color: #333
          .mask-fixed
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            z-index: 999
            display: flex
            justify-content: center
            align-items: center
            background-color: rgba(0, 0, 0, 0.5)
            .el-button--mini
              padding: 7px 10px !important
        li:hover
          transform: translateY(-2px)
          box-shadow: 0 6px 10px 0 rgba(95, 101, 105, 0.15)
        li:nth-child(3n)
          margin-right: 0
.pop-up-box
  width: 100%
  height: auto
  display: flex
  justify-content: center
</style>
